import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { useEffect, startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
import * as Sentry from "@sentry/remix"

Sentry.init({
  dsn: "https://01946d822449436fb21a20c9cd4ca4db:5dad950e3b40471786be4137f2937135@o471.ingest.sentry.io/4505077942452224",
  release: "SENTRY-RELEASE-PLACEHOLDER",
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(
        useEffect,
        useLocation,
        useMatches,
      ),
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
